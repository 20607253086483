@import url('./reset.less');

// body {
//   background-color: #0D2A42;
// }
.App {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.clearfix {
  zoom: 1;
}

.main-container {
  height: 100%;
  padding: 5%;
  overflow-y: scroll;
  text-align: justify;
}

.main-title-level-1 {
  font-size: 20px;
  padding-bottom: 20px;
  font-weight: bold;
}

.main-title-level-2 {
  font-size: 18px;
  padding-top: 10px;
  line-height: 24px;
  padding-bottom: 20px;
  font-weight: bold;
}

.main-title-level-3 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.common-p {
  font-size: 14px;
  text-indent: 2em;
  line-height: 22px;
  margin-bottom: 16px;
  text-align: justify;
}

.common-p-sub {
  font-size: 14px;
  text-indent: 2em;
  line-height: 22px;
  margin-bottom: 10px;
}

.ul-list {
  .li-item {
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.bottom-mark {
  text-align: right;
  font-size: 14px;
  margin-top: 20px;
}

.con-text {
  font-family: '宋体';
}

.con-div {
  margin-top: 0pt;
  margin-bottom: 0pt;
  text-indent: 21pt;
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-size: 10.5pt;
}

.con-div-1 {
  margin-top: 0pt;
  margin-bottom: 0pt;
  text-align: center;
  widows: 0;
  orphans: 0;
  font-size: 10.5pt;
  padding-bottom: 10px;
  font-weight: 900;
}

.con-div-2 {
  margin-top: 0pt;
  margin-bottom: 0pt;
  text-indent: 21pt;
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-size: 10.5pt;
}

.con-div-3 {
  margin-top: 0pt;
  margin-bottom: 0pt;
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-size: 10.5pt;
}

.con-p-1 {
  margin-top: 0pt;
  margin-bottom: 10pt;
  text-align: center;
  widows: 0;
  orphans: 0;
  font-size: 16pt;
}

.con-p-2 {
  margin-top: 0pt;
  margin-bottom: 0pt;
  text-indent: 21pt;
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-size: 10.5pt;
}

.con-p-3 {
  margin-top: 0pt;
  margin-bottom: 0pt;
  text-align: justify;
  widows: 0;
  orphans: 0;
  font-size: 10.5pt;
}

.con-p-4 {
  margin-top: 0pt;
  margin-bottom: 0pt;
  text-indent: 21pt;
  font-size: 10.5pt;
}

.con-p-5 {
  margin-top: 0pt;
  margin-bottom: 0pt;
  font-size: 10.5pt;
}

.con-text-1 {
  font-family: '宋体';
  font-weight: bold;
}

.con-text-2 {
  font-family: '宋体';
}

.con-text-3 {
  font-family: Calibri;
}

.con-text-4 {
  font-family: '宋体';
  color: #333333;
}

.con-text-5 {
  font-family: 'Segoe UI';
  color: #333333;
}